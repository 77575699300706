footer {
  .footer-top {
    margin-bottom: -10px;
  }

  .footer-content {
    background-color: var(--background-primary);
    padding: 16px;
    p {
      margin: 8px 0px;
    }
  }
}
