:root {
  --primary: #494c57;
  --secondary: #f2f2f2;
  --white: #ffffff;

  --button-primary: #576afd;
  --button-secondary: #f2f2f2;

  --background-primary: #ddf0ff;
  --background-secondary: #7ed9d1;
}

* {
  color: var(--primary);
  box-sizing: border-box;
}

html {
  font-family: 'Barlow', sans-serif;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  margin: 0 auto;
}

main {
  padding-top: 100px;
}

.separator-vertical {
  height: 50px;
  width: 1px;
  background-color: var(--primary);
}

/* text */

p {
  font-family: 'Barlow', sans-serif;
  font-size: 1.1em;
  line-height: 150%;
  font-weight: 300;
}

.color-text-white {
  color: var(--white);
}

.color-text-primary {
  color: var(--primary);
}

.text-center {
  text-align: center;
}

.text-display {
  font-family: 'Poppins', sans-serif;
  font-size: 1.8em;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media screen and (min-width: 821px) {
    font-size: 2.5em;
  }
}

.text-display-h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.text-display-h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 1.17em;
}

.text-display-h4 {
  font-size: 1em;
}

.text-uppercase {
  text-transform: uppercase;
}

.block-separator {
  height: 250px;
  width: 100%;
}

/* button */
.button-primary {
  background-color: var(--button-primary);
  border: none;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--white);
  z-index: 1;
  position: relative;
  height: 50px;
  text-decoration: none;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-secondary {
  background-color: var(--button-secondary);
  border: none;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--primary);
  z-index: 1;
  position: relative;
  height: 50px;
  text-decoration: none;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* flex row */
.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  @media screen and (min-width: 821px) {
    flex-direction: row;
  }
}

/* col from 1 to 12 */
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 821px) {
  .col-12 {
    width: 100%;
  }
  .col-11 {
    width: 91.6666666667%;
  }
  .col-10 {
    width: 83.3333333333%;
  }
  .col-9 {
    width: 75%;
  }
  .col-8 {
    width: 66.6666666667%;
  }
  .col-7 {
    width: 58.3333333333%;
  }
  .col-6 {
    width: 50%;
  }
  .col-5 {
    width: 41.6666666667%;
  }
  .col-4 {
    width: 33.3333333333%;
  }
  .col-3 {
    width: 25%;
  }
  .col-2 {
    width: 16.6666666667%;
  }
  .col-1 {
    width: 8.3333333333%;
  }
}

/* flex utils */

.flex {
  display: flex;
}

.align-top {
  align-items: flex-start;
}

.align-stretch {
  align-items: stretch;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-center {
  justify-content: center;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

/* spacing */

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-64 {
  margin-bottom: 64px;
}

.pd-16 {
  padding: 16px;
}

.pd-32 {
  padding: 32px;
}

/* paragraph */
.paragraph-container {
  padding: 1em;
}

/* paper style */

.paper {
  background-color: var(--background-primary);
  border-radius: 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  margin: 1em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .paper-bottom {
    margin-top: 1em;
  }
}

.elevation {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
