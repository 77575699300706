.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 100px;
  background-color: var(--background-primary);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-width: 2000px;
  margin: 0 auto;
  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-primary);
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
    .navbar-list {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
      text-decoration: unset;
      list-style: none;
      padding: 0;
      .separator-vertical {
        display: none;
      }
      li a {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 300;
      }
      li a:hover {
        font-weight: 600;
      }

      .navbar-link.login {
        background-color: transparent;
        border: 1px solid var(--button-primary);
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-size: 0.9em;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;
        color: var(--button-primary);
      }
    }
    &.active {
      transform: translateX(0);
    }
  }
  @media screen and (min-width: 821px) {
    .navbar {
      position: relative;
      top: unset;
      right: unset;
      left: unset;
      width: unset;
      height: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      transform: translateX(0);
      transition: all 0.3s ease-in-out;
      .navbar-list {
        flex-direction: row;
        align-items: center;
        padding: 0;
        display: flex;
        margin: 0;
        gap: 2em;
        text-decoration: unset;
        list-style: none;
      }
    }
  }
}

.logo-container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  p {
    font-size: 1.1em;
    line-height: 100%;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.logo {
  margin-right: 1rem;
}

.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  .line {
    width: 30px;
    height: 2px;
    background-color: var(--primary);
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    &:nth-child(2) {
      width: 20px;
    }
  }
  &.active {
    .line:nth-child(1) {
      transform: rotate(45deg) translate(5px, 4px);
    }
    .line:nth-child(2) {
      opacity: 0;
    }
    .line:nth-child(3) {
      transform: rotate(-45deg) translate(7px, -7px);
    }
  }

  @media screen and (min-width: 821px) {
    display: none;
  }
}
