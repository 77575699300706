html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --primary: #494c57;
  --secondary: #f2f2f2;
  --white: #fff;
  --button-primary: #576afd;
  --button-secondary: #f2f2f2;
  --background-primary: #ddf0ff;
  --background-secondary: #7ed9d1;
}

* {
  color: var(--primary);
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-family: Barlow, sans-serif;
}

body {
  max-width: 2000px;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
}

main {
  padding-top: 100px;
}

.separator-vertical {
  height: 50px;
  width: 1px;
  background-color: var(--primary);
}

p {
  font-family: Barlow, sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 150%;
}

.color-text-white {
  color: var(--white);
}

.color-text-primary {
  color: var(--primary);
}

.text-center {
  text-align: center;
}

.text-display {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 1.8em;
  font-weight: 700;
  line-height: 125%;
}

@media screen and (min-width: 821px) {
  .text-display {
    font-size: 2.5em;
  }
}

.text-display-h2 {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 125%;
}

.text-display-h3 {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 1.17em;
  font-weight: 700;
  line-height: 125%;
}

.text-display-h4 {
  font-size: 1em;
}

.text-uppercase {
  text-transform: uppercase;
}

.block-separator {
  height: 250px;
  width: 100%;
}

.button-primary {
  background-color: var(--button-primary);
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--white);
  z-index: 1;
  height: 50px;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  padding: .5rem 1rem;
  font-size: .9em;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button-secondary {
  background-color: var(--button-secondary);
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--primary);
  z-index: 1;
  height: 50px;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  padding: .5rem 1rem;
  font-size: .9em;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  position: relative;
}

.row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: flex;
}

@media screen and (min-width: 821px) {
  .row {
    flex-direction: row;
  }
}

.col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  width: 100%;
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 821px) {
  .col-12 {
    width: 100%;
  }

  .col-11 {
    width: 91.6667%;
  }

  .col-10 {
    width: 83.3333%;
  }

  .col-9 {
    width: 75%;
  }

  .col-8 {
    width: 66.6667%;
  }

  .col-7 {
    width: 58.3333%;
  }

  .col-6 {
    width: 50%;
  }

  .col-5 {
    width: 41.6667%;
  }

  .col-4 {
    width: 33.3333%;
  }

  .col-3 {
    width: 25%;
  }

  .col-2 {
    width: 16.6667%;
  }

  .col-1 {
    width: 8.33333%;
  }
}

.flex {
  display: flex;
}

.align-top {
  align-items: flex-start;
}

.align-stretch {
  align-items: stretch;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-center {
  justify-content: center;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-64 {
  margin-bottom: 64px;
}

.pd-16 {
  padding: 16px;
}

.pd-32 {
  padding: 32px;
}

.paragraph-container {
  padding: 1em;
}

.paper {
  background-color: var(--background-primary);
  border-radius: 15px;
  flex-direction: column;
  flex-grow: 1;
  margin: 1em;
  display: flex;
  box-shadow: 0 0 30px #0003;
}

.paper .paper-bottom {
  margin-top: 1em;
}

.elevation {
  box-shadow: 0 0 30px #0003;
}

.img-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

footer .footer-top {
  margin-bottom: -10px;
}

footer .footer-content {
  background-color: var(--background-primary);
  padding: 16px;
}

footer .footer-content p {
  margin: 8px 0;
}

.header-container {
  height: 100px;
  background-color: var(--background-primary);
  z-index: 100;
  max-width: 2000px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header-container .navbar {
  width: 100%;
  height: 100vh;
  background-color: var(--background-primary);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

.header-container .navbar .navbar-list {
  -webkit-text-decoration: unset;
  text-decoration: unset;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 0;
  list-style: none;
  display: flex;
}

.header-container .navbar .navbar-list .separator-vertical {
  display: none;
}

.header-container .navbar .navbar-list li a {
  text-transform: uppercase;
  font-weight: 300;
  text-decoration: none;
}

.header-container .navbar .navbar-list li a:hover {
  font-weight: 600;
}

.header-container .navbar .navbar-list .navbar-link.login {
  border: 1px solid var(--button-primary);
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--button-primary);
  background-color: #0000;
  border-radius: 5px;
  padding: .5rem 1rem;
  font-size: .9em;
  font-weight: 600;
}

.header-container .navbar.active {
  transform: translateX(0);
}

@media screen and (min-width: 821px) {
  .header-container .navbar {
    top: unset;
    right: unset;
    left: unset;
    width: unset;
    height: unset;
    background-color: #0000;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
    display: flex;
    position: relative;
    transform: translateX(0);
  }

  .header-container .navbar .navbar-list {
    -webkit-text-decoration: unset;
    text-decoration: unset;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
}

.logo-container {
  z-index: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.logo-container p {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 100%;
}

.logo {
  margin-right: 1rem;
}

.burger-menu {
  cursor: pointer;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
  display: flex;
  position: relative;
}

.burger-menu .line {
  width: 30px;
  height: 2px;
  background-color: var(--primary);
  border-radius: 5px;
  transition: all .3s ease-in-out;
}

.burger-menu .line:nth-child(2) {
  width: 20px;
}

.burger-menu.active .line:first-child {
  transform: rotate(45deg)translate(5px, 4px);
}

.burger-menu.active .line:nth-child(2) {
  opacity: 0;
}

.burger-menu.active .line:nth-child(3) {
  transform: rotate(-45deg)translate(7px, -7px);
}

@media screen and (min-width: 821px) {
  .burger-menu {
    display: none;
  }
}

/*# sourceMappingURL=index.5beb3506.css.map */
